import { HeadingMd } from './Heading'
import { Icon } from './Icon'
import { determineLinkProps, resolveLinkObjectToHref } from '/machinery/link'

import arrowIcon from '/images/icons/arrow.raw.svg'

import styles from './QuickLinks.css'

export function QuickLinks({ title, links }) {
  return (
    <div className={styles.component}>
      <div className={styles.heading}><HeadingMd h={2} {... { title }} /></div>
      <ul className={styles.list} data-style-context="light-alt">
        {links.map((link, i) => (
          <li key={i}>
            <Link title={link.label} href={resolveLinkObjectToHref(link)} targetSelf={link.targetSelf}  />
          </li>
        ))}
      </ul>
    </div>
  )
}

function Link({ title, href, targetSelf }) {
  const [hover, setHover] = React.useState(false)
  const { rel, target } = determineLinkProps({ href, targetSelf })

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.componentLink}>
      <div>
        <h4 className={styles.title}>{title}</h4>
      </div>
      <a className={styles.link} {...{ href, rel, target }}>
        <span className={cx(styles.icon, hover && styles.fill)}>
          <Icon icon={arrowIcon} />
        </span>
      </a>
    </div>
  )
}
